<template>
  <div class="payment-check">
    <img src="@/assets/images/voicemod-loader.gif">
    <div class="payment-check__text">
      {{ $t('checkout.checking-purchase') }}
    </div>
  </div>
</template>

<script>
import { CheckoutClient } from '@/services/models/checkout';
import { AccountClient } from '@/services/models/accounts';
import { CheckoutSession } from '@/services/checkout';
import { ROUTER_PATHS } from '@/router/paths';

const XSOLLA_STATUS = {
    TROUBLED: 'troubled',
};
const EXCEDED_CHECKOUT_TIME = 60000;
const POLLING_PERIOD = 5000;

export default {
    data() {
        return {
            intervalId: null,
            timeoutId: null,
            checkoutData: null,
        };
    },
    async mounted() {
        this.xsollaStatus = this.$route.query.status;
        if(this.xsollaStatus === XSOLLA_STATUS.TROUBLED || !this.xsollaStatus) {
            this.goToError();
            return;
        }

        this.checkoutData = CheckoutSession.get();

        if(this.checkoutData.client) {
            this.checkPurchaseForFlagship(this.checkoutData);
            this.doPoolingForFlagship();
        }

        if(!this.checkoutData.client) {
            this.checkPurchase();
            this.doPooling();
        }


        this.finishOnTimeout();
    },
    methods: {
        goToError() {
            this.$router.push(ROUTER_PATHS.PAYMENT_ERROR);
        },
        async checkPurchase() {
            const { status } = await CheckoutClient.checkPaymentStatus(this.checkoutData);
            if(status === 200) {
                this.clearAllIntervals([this.intervalId, this.timeoutId]);
                this.$router.push(ROUTER_PATHS.PAYMENT_SUCCESS);
                return;
            }
        },
        async checkPurchaseForFlagship({ userId }) {
            const { data: userInfo } = await AccountClient.getUserInfo(userId);

            if(userInfo.subscription.subscriptionType === 'pro') {
                this.clearAllIntervals([this.intervalId, this.timeoutId]);
                this.$router.push({
                    path: ROUTER_PATHS.PAYMENT_SUCCESS,
                    query: {
                        client: 'flagship',
                    },
                });
                return;
            }
        },
        clearAllIntervals(timings) {
            timings.forEach(timings => {
                clearInterval(timings);
                clearTimeout(timings);
            });
        },
        doPooling() {
            setTimeout(() => {
                this.intervalId = setInterval(async () => {
                    await this.checkPurchase(this.checkoutData);
                }, POLLING_PERIOD);
            }, POLLING_PERIOD);
        },
        doPoolingForFlagship() {
            setTimeout(() => {
                this.intervalId = setInterval(async () => {
                    await this.checkPurchaseForFlagship(this.checkoutData);
                }, POLLING_PERIOD);
            }, POLLING_PERIOD);
        },
        finishOnTimeout() {
            this.timeoutId = setTimeout(() => {
                this.clearAllIntervals([this.intervalId]);
                this.$router.push(ROUTER_PATHS.PAYMENT_ERROR);
            },EXCEDED_CHECKOUT_TIME);
        },
    },
};
</script>

<style src="./PaymentCheckView.scss" lang="scss">
</style>
